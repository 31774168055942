<template>
  <main-layout>
    <main class="section">
      <div class="container">
        <div class="flash-message">
          <div class="flash-message-level">
            <span class="icon is-large">
              <i class="fas fa-desktop fa-2x"></i>
            </span>
          </div>
          <div class="flash-message-content">
            <p><strong v-t="`flash-message-content.desktop`"></strong></p>
            <p v-t="`flash-message-content.open-email`"></p>
          </div>
        </div>

        <div class="spacer is-medium"></div>

        <div class="content has-text-centered">
          <h1 class="title is-2 is-size-3-mobile has-text-weight-black">
            <span class="has-text-secondary" v-t="`flash-message-content.instant-savings`"></span>
            <span></span>

          </h1>

          <p class="has-text-grey is-size-4 is-size-5-mobile"
            v-t="`flash-message-content.ai-powered`"
          ></p>

          <div class="spacer"></div>

          <p>
            <a
              class="button is-messenger is-medium is-fullwidth has-text-weight-semibold"
              :href="`${$config.messengerBot.url}${this.ref}`"
              target="_blank" ref="noopener"
            >
              <i class="fab fa-facebook-messenger"></i>
              &nbsp;
              <span v-t="`buttons.sign-up`"></span>
            </a>
          </p>

          <p>
            <span class="icon is-large">
              <i class="fas fa-angle-down fa-2x has-text-primary"></i>
            </span>
          </p>

          <p><img v-lazy="`$/images/messenger_example.png`" alt="Messenger Example"></p>
        </div>
      </div>
    </main>
  </main-layout>
</template>

<script>
import Utm from '@/core/services/Utm'

export default {
  computed: {
    ref () {
      return `desktop-only--${(Utm.get().utm_source || 'organic')}`
    }
  }
}
</script>
